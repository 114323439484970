<template lang="pug">
.progress_bar.w-100.d-flex.flex-column(:class="{ 'align-end': reverse }")
  .data.d-flex.align-center(
    :class="[reverse ? 'flex-row-reverse' : 'flex-row']"
  )
    h6.text-h6.d-inline-block.font-weight-bold(
      v-if="rate",
      style="font-family: Helvetica"
    ) {{ value }}/{{ deno }}
    h6.text-h6.d-inline-block.font-weight-bold(
      v-else-if="!rate",
      style="font-family: Helvetica"
    ) {{ value }}
    span.text-body-1.font-weight-bold(
      v-if="rate",
      :class="[reverse ? 'reverse_margin' : 'margin']",
      style="color: #888888"
    ) {{ type != 'atRatio' ? getPercentage : getRate }}
  .progress.mt-2(:class="[reverse ? 'bg_secondary' : 'bg_middle_blue']")
</template>

<script>
export default {
  name: "PercentageBar",
  props: {
    type: String,
    value: Number,
    reverse: { type: Boolean, default: false },
    deno: {
      type: Number,
      default: 100,
    },
    rate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getPercentage() {
      return ((this.value / this.deno) * 100).toFixed(1) + "%";
    },
    getRate() {
      console.log("type=", this.type);
      return (this.value / this.deno).toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
.progress {
  height: 8px;
  width: 100%;
  border-radius: 4px;
}

.reverse {
  align-items: right !important;
}

.reverse_margin {
  margin-right: 10px;
}

.margin {
  margin-left: 10px;
}

@media (max-width: 599px) {
  .progress {
    height: 6px;
    width: 100%;
    border-radius: 3px;
  }
}
</style>
