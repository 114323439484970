<template lang="pug">
.box_score
  v-container.tag_container(style="padding: 0", fluid)
    v-tabs.tag-switcher(
      background-color="transparent",
      ripple="false",
      v-model="tab",
      centered
    )
      v-tabs-slider(hidden)
      v-tab(@click="switchTable(0)", :ripple="false") {{ gameData.home_team_name }}
      v-tab(@click="switchTable(1)", :ripple="false") {{ gameData.away_team_name }}
  v-container.mt-10
    v-row(justify="end")
      v-col.pb-0(cols="auto")
        v-icon(small, color="error") mdi-information-outline
        span.overline.text_secondary.ml-1 點擊數據觀看精彩影片
    v-row.table_row.mt-0
      v-col#sticky-nav.table.table_col
        table
          thead
            tr.top_head
              sticky-column.bdr2(colspan="1", :offset="1")
              th.bdr(colspan="1") 登錄
              th.bdr(colspan="1") 
              th.bdr(colspan="1") 時間
              th.bdr(colspan="5") 
              th.bdr(colspan="3") 投籃
              th.bdr(colspan="3") 二分
              th.bdr(colspan="3") 三分
              th.bdr(colspan="3") 罰球
              th.bdr(colspan="2") 籃板
              th(colspan="8")
            tr.bottom_head
              sticky-column.bdl(
                style="width: 50px; min-width: 50px",
                :title="'先發'",
                :offset="0"
              )
              sticky-column(
                style="width: 50px; min-width: 50px",
                :title="'背號'",
                :offset="50"
              )
              sticky-column.bdr2(
                style="width: 100px; min-width: 100px",
                :title="'球員'",
                :offset="100"
              )
              th.bdr(
                style="cursor: pointer",
                @click="sortClick('secs', 'BOX')",
                :class="{ sort_up: sortValue.type == 'secs' && sortValue.order == 'ASC', sort_down: sortValue.type == 'secs' && sortValue.order == 'DESC' }"
              )
                span 上場時間
                font-awesome-icon.ml-2(:icon="getTableSort('on_court_secs')")
              th(
                style="cursor: pointer",
                @click="sortClick('pts', 'BOX')",
                :class="{ sort_up: sortValue.type == 'pts' && sortValue.order == 'ASC', sort_down: sortValue.type == 'pts' && sortValue.order == 'DESC' }"
              ) 
                span 得分
                font-awesome-icon.ml-2(:icon="getTableSort('pts')")
              th(
                style="cursor: pointer",
                @click="sortClick('reb', 'BOX')",
                :class="{ sort_up: sortValue.type == 'reb' && sortValue.order == 'ASC', sort_down: sortValue.type == 'reb' && sortValue.order == 'DESC' }"
              ) 
                span 籃板
                font-awesome-icon.ml-2(:icon="getTableSort('reb')")
              th(
                style="cursor: pointer",
                @click="sortClick('ast', 'BOX')",
                :class="{ sort_up: sortValue.type == 'ast' && sortValue.order == 'ASC', sort_down: sortValue.type == 'ast' && sortValue.order == 'DESC' }"
              ) 
                span 助攻
                font-awesome-icon.ml-2(:icon="getTableSort('ast')")
              th(
                style="cursor: pointer",
                @click="sortClick('stl', 'BOX')",
                :class="{ sort_up: sortValue.type == 'stl' && sortValue.order == 'ASC', sort_down: sortValue.type == 'stl' && sortValue.order == 'DESC' }"
              ) 
                span 抄截
                font-awesome-icon.ml-2(:icon="getTableSort('stl')")
              th.bdr(
                style="cursor: pointer",
                @click="sortClick('blk', 'BOX')",
                :class="{ sort_up: sortValue.type == 'blk' && sortValue.order == 'ASC', sort_down: sortValue.type == 'blk' && sortValue.order == 'DESC' }"
              ) 
                span 阻攻
                font-awesome-icon.ml-2(:icon="getTableSort('blk')")
              th(
                style="cursor: pointer",
                @click="sortClick('fgm', 'BOX')",
                :class="{ sort_up: sortValue.type == 'fgm' && sortValue.order == 'ASC', sort_down: sortValue.type == 'fgm' && sortValue.order == 'DESC' }"
              )
                span 命中
                font-awesome-icon.ml-2(:icon="getTableSort('fgm')")
              th(
                style="cursor: pointer",
                @click="sortClick('fga', 'BOX')",
                :class="{ sort_up: sortValue.type == 'fga' && sortValue.order == 'ASC', sort_down: sortValue.type == 'fga' && sortValue.order == 'DESC' }"
              )
                span 出手
                font-awesome-icon.ml-2(:icon="getTableSort('fg_a')")
              th.bdr(
                style="cursor: pointer",
                @click="sortClick('fg_percent', 'BOX')",
                :class="{ sort_up: sortValue.type == 'fg_percent' && sortValue.order == 'ASC', sort_down: sortValue.type == 'fg_percent' && sortValue.order == 'DESC' }"
              ) 
                span 命中率
                font-awesome-icon.ml-2(:icon="getTableSort('fg_percent')")
              th(
                style="cursor: pointer",
                @click="sortClick('two_pt_fgm', 'BOX')",
                :class="{ sort_up: sortValue.type == 'two_pt_fgm' && sortValue.order == 'ASC', sort_down: sortValue.type == 'two_pt_fgm' && sortValue.order == 'DESC' }"
              )
                span 命中
                font-awesome-icon.ml-2(:icon="getTableSort('two_pt_fgm')")
              th(
                style="cursor: pointer",
                @click="sortClick('two_pt_fga', 'BOX')",
                :class="{ sort_up: sortValue.type == 'two_pt_fga' && sortValue.order == 'ASC', sort_down: sortValue.type == 'two_pt_fga' && sortValue.order == 'DESC' }"
              )
                span 出手
                font-awesome-icon.ml-2(:icon="getTableSort('two_pt_fga')")
              th.bdr(
                style="cursor: pointer",
                @click="sortClick('two_pt_fg_percent', 'BOX')",
                :class="{ sort_up: sortValue.type == 'two_pt_fg_percent' && sortValue.order == 'ASC', sort_down: sortValue.type == 'two_pt_fg_percent' && sortValue.order == 'DESC' }"
              ) 
                span 命中率
                font-awesome-icon.ml-2(
                  :icon="getTableSort('two_pt_fg_percent')"
                )
              th(
                style="cursor: pointer",
                @click="sortClick('three_pt_fg_m', 'BOX')",
                :class="{ sort_up: sortValue.type == 'three_pt_fg_m' && sortValue.order == 'ASC', sort_down: sortValue.type == 'three_pt_fg_m' && sortValue.order == 'DESC' }"
              )
                span 命中
                font-awesome-icon.ml-2(:icon="getTableSort('three_pt_fg_m')")
              th(
                style="cursor: pointer",
                @click="sortClick('three_pt_fga', 'BOX')",
                :class="{ sort_up: sortValue.type == 'three_pt_fga' && sortValue.order == 'ASC', sort_down: sortValue.type == 'three_pt_fga' && sortValue.order == 'DESC' }"
              ) 
                span 出手
                font-awesome-icon.ml-2(:icon="getTableSort('three_pt_fga')")
              th.bdr(
                style="cursor: pointer",
                @click="sortClick('three_pt_fg_percent', 'BOX')",
                :class="{ sort_up: sortValue.type == 'three_pt_fg_percent' && sortValue.order == 'ASC', sort_down: sortValue.type == 'three_pt_fg_percent' && sortValue.order == 'DESC' }"
              ) 
                span 命中率
                font-awesome-icon.ml-2(
                  :icon="getTableSort('three_pt_fg_percent')"
                )
              th(
                style="cursor: pointer",
                @click="sortClick('ftm', 'BOX')",
                :class="{ sort_up: sortValue.type == 'ftm' && sortValue.order == 'ASC', sort_down: sortValue.type == 'ftm' && sortValue.order == 'DESC' }"
              ) 
                span 命中
                font-awesome-icon.ml-2(:icon="getTableSort('ftm')")
              th(
                style="cursor: pointer",
                @click="sortClick('fta', 'BOX')",
                :class="{ sort_up: sortValue.type == 'fta' && sortValue.order == 'ASC', sort_down: sortValue.type == 'fta' && sortValue.order == 'DESC' }"
              ) 
                span 出手
                font-awesome-icon.ml-2(:icon="getTableSort('ft_a')")
              th.bdr(
                style="cursor: pointer",
                @click="sortClick('ft_percent', 'BOX')",
                :class="{ sort_up: sortValue.type == 'ft_percent' && sortValue.order == 'ASC', sort_down: sortValue.type == 'ft_percent' && sortValue.order == 'DESC' }"
              ) 
                span 命中率
                font-awesome-icon.ml-2(:icon="getTableSort('ft_percent')")
              th(
                style="cursor: pointer",
                @click="sortClick('off_reb', 'BOX')",
                :class="{ sort_up: sortValue.type == 'off_reb' && sortValue.order == 'ASC', sort_down: sortValue.type == 'off_reb' && sortValue.order == 'DESC' }"
              ) 
                span 進攻
                font-awesome-icon.ml-2(:icon="getTableSort('off_reb')")
              th.bdr(
                style="cursor: pointer",
                @click="sortClick('def_reb', 'BOX')",
                :class="{ sort_up: sortValue.type == 'def_reb' && sortValue.order == 'ASC', sort_down: sortValue.type == 'def_reb' && sortValue.order == 'DESC' }"
              ) 
                span 防守
                font-awesome-icon.ml-2(:icon="getTableSort('def_reb')")
              th(
                style="cursor: pointer",
                @click="sortClick('tov', 'BOX')",
                :class="{ sort_up: sortValue.type == 'tov' && sortValue.order == 'ASC', sort_down: sortValue.type == 'tov' && sortValue.order == 'DESC' }"
              ) 
                span 失誤
                font-awesome-icon.ml-2(:icon="getTableSort('tov')")
              th(
                style="cursor: pointer",
                @click="sortClick('pf', 'BOX')",
                :class="{ sort_up: sortValue.type == 'pf' && sortValue.order == 'ASC', sort_down: sortValue.type == 'pf' && sortValue.order == 'DESC' }"
              ) 
                span 犯規
                font-awesome-icon.ml-2(:icon="getTableSort('pf')")
              th(
                style="cursor: pointer",
                @click="sortClick('plus_minus', 'BOX')",
                :class="{ sort_up: sortValue.type == 'plus_minus' && sortValue.order == 'ASC', sort_down: sortValue.type == 'plus_minus' && sortValue.order == 'DESC' }"
              ) 
                span +/-
                font-awesome-icon.ml-2(:icon="getTableSort('plus_minus')")
              th(
                style="cursor: pointer",
                @click="sortClick('eff', 'BOX')",
                :class="{ sort_up: sortValue.type == 'eff' && sortValue.order == 'ASC', sort_down: sortValue.type == 'eff' && sortValue.order == 'DESC' }"
              ) 
                span EFF
                font-awesome-icon.ml-2(:icon="getTableSort('eff')")
              th(
                style="cursor: pointer",
                @click="sortClick('at_ratio', 'BOX')",
                :class="{ sort_up: sortValue.type == 'at_ratio' && sortValue.order == 'ASC', sort_down: sortValue.type == 'at_ratio' && sortValue.order == 'DESC' }"
              ) 
                span A/T
                font-awesome-icon.ml-2(:icon="getTableSort('at_ratio')")
          tbody
            tr(v-for="(player, index) in currentPlayerGameData", :key="index")
              sticky-column.bdl(
                :class="{ text_secondary: true }",
                style="width: 50px; min-width: 50px",
                :title="player.is_start ? '◯' : ''",
                :offset="0"
              ) 
              sticky-column(
                style="width: 50px; min-width: 50px",
                :title="player.season_player__jersey_number.toString()",
                :offset="50"
              )
              sticky-column.clickable.bdr2(
                @click.native="playerClick(player)",
                style="width: 100px; min-width: 100px",
                :title="player.season_player__name",
                :offset="100"
              )
              td.bdr {{ getMinutes(player.total.secs) }}
              td(
                @click="dataClick(player, 'pts', '得分')",
                :class="[player.total.pts > 0 ? 'link_deep' : 'gray_out_heavy']"
              ) {{ player.total.pts }}
              td(
                @click="dataClick(player, 'reb', '籃板')",
                :class="[player.total.reb > 0 ? 'link_deep' : 'gray_out_heavy']"
              ) {{ player.total.reb }}
              td(
                @click="dataClick(player, 'ast', '助攻')",
                :class="[player.total.ast > 0 ? 'link_deep' : 'gray_out_heavy']"
              ) {{ player.total.ast }}
              td(
                @click="dataClick(player, 'stl', '抄截')",
                :class="[player.total.stl > 0 ? 'link_deep' : 'gray_out_heavy']"
              ) {{ player.total.stl }}
              td.bdr(
                @click="dataClick(player, 'blk', '阻攻')",
                :class="[player.total.blk > 0 ? 'link_deep' : 'gray_out_heavy']"
              ) {{ player.total.blk }}
              td(
                @click="dataClick(player, 'fgm', '投籃命中')",
                :class="[player.total.fgm > 0 ? 'link' : 'gray_out']"
              ) {{ player.total.fgm }}
              td(
                @click="dataClick(player, 'fga', '投籃出手')",
                :class="[player.total.fga > 0 ? 'link' : 'gray_out']"
              ) {{ player.total.fga }}
              td.bdr {{ (player.total.fg_percent * 100).toFixed(1) }}%
              td(
                @click="dataClick(player, 'two_pt_fgm', '二分命中')",
                :class="[player.total.two_pt_fgm > 0 ? 'link' : 'gray_out']"
              ) {{ player.total.two_pt_fgm }}
              td(
                @click="dataClick(player, 'two_pt_fga', '二分出手')",
                :class="[player.total.two_pt_fga > 0 ? 'link' : 'gray_out']"
              ) {{ player.total.two_pt_fga }}
              td.bdr {{ (player.total.two_pt_fg_percent * 100).toFixed(1) }}%
              td(
                @click="dataClick(player, 'three_pt_fgm', '三分命中')",
                :class="[player.total.three_pt_fgm > 0 ? 'link' : 'gray_out']"
              ) {{ player.total.three_pt_fgm }}
              td(
                @click="dataClick(player, 'three_pt_fga', '三分出手')",
                :class="[player.total.three_pt_fga > 0 ? 'link' : 'gray_out']"
              ) {{ player.total.three_pt_fga }}
              td.bdr {{ (player.total.three_pt_fg_percent * 100).toFixed(1) }}%
              td(
                @click="dataClick(player, 'ftm', '罰球命中')",
                :class="[player.total.ftm > 0 ? 'link' : 'gray_out']"
              ) {{ player.total.ftm }}
              td(
                @click="dataClick(player, 'fta', '罰球出手')",
                :class="[player.total.fta > 0 ? 'link' : 'gray_out']"
              ) {{ player.total.fta }}
              td.bdr {{ (player.total.ft_percent * 100).toFixed(1) }}%
              td(
                @click="dataClick(player, 'off_reb', '進攻籃板')",
                :class="[player.total.off_reb > 0 ? 'link' : 'gray_out']",
                default=0
              ) {{ player.total.off_reb }}
              td.bdr(
                @click="dataClick(player, 'def_reb', '防守籃板')",
                :class="[player.total.def_reb > 0 ? 'link' : 'gray_out']"
              ) {{ player.total.def_reb }}
              td(
                @click="dataClick(player, 'tov', '失誤')",
                :class="[player.total.tov > 0 ? 'link' : 'gray_out']"
              ) {{ player.total.tov }}
              td(
                @click="dataClick(player, 'pf', '犯規')",
                :class="[player.total.pf > 0 ? 'link' : 'gray_out']"
              ) {{ player.total.pf }}
              td {{ player.total.plus_minus }}
              td {{ player.total.eff }}
              td {{ player.total.at_ratio.toFixed(2) }}
  v-container
    v-row(style="margin-top: 100px")
      v-col.text-center
        h5.normal_title_h5 數據比較
    v-row
      v-col(cols="2")
      v-col.d-flex.titles.justify-space-between(cols="12", sm="8")
        h6.bar_title.clickable.text_deep_gray(@click="teamClick(true)") {{ gameData.home_team_name }}
        h6.bar_title.clickable.text_deep_gray(@click="teamClick(false)") {{ gameData.away_team_name }}
    v-row.align-center.mt-5(
      v-for="(ranking, index) in teamRankingList",
      :key="index"
    )
      v-col.text-right.pb-0.pb-sm-4(cols="12", sm="2")
        h6.bar_title.type {{ ranking.title }}
      v-col.d-flex.justify-space-between.pt-0.pt-sm-4(cols="12", sm="8")
        percentage-compare-bar.flex-shrink-1(
          :style="{ maxWidth: 'calc(' + getPercentageCompareRate(ranking.type, true) + ' - 1vw)' }",
          :type="ranking.type",
          :value="ranking.home.value",
          :deno="ranking.home.deno",
          :rate="ranking.home.deno != null"
        )
        percentage-compare-bar.flex-shrink-1(
          :style="{ maxWidth: 'calc(' + getPercentageCompareRate(ranking.type, false) + ' - 1vw)' }",
          :type="ranking.type",
          :value="ranking.away.value",
          :deno="ranking.away.deno",
          :reverse="true",
          :rate="ranking.home.deno != null"
        )
  v-container
    v-row(style="margin-top: 100px")
      v-col.text-center(cols="12")
        h5.normal_title_h5 最佳球員
    v-row.justify-center
      v-col.text-center.mt-6(
        v-for="(ranking, index) in gameData.player_stat_rankings",
        :key="index",
        cols="12",
        sm="10"
      )
        .player_data.d-flex.justify-space-between.align-center
          .home_player.d-flex.align-center
            .avator_container.temp_logo_hidden
              v-img.img_circle.clickable.mr-4(
                :src="ranking.home_team.img",
                @click="teamClick(true)"
              )
            .player_text.text-left
              h4.font-weight-bold.text-h4.text_middle_blue {{ ranking.home_team.value }}
              div(
                v-for="(player, i) in gameData.player_stat_rankings[index].home_team.players"
              )
                span.player_name.clickable(@click="bestPlayerClick(player)") {{ `${player.name}` }}
          span.type.font-weight-bold {{ ranking.title }}
          .away_player.d-flex.align-center
            .player_text.text-right
              h4.font-weight-bold.text-h4.text_secondary {{ ranking.away_team.value }}
              div(
                v-for="(player, i) in gameData.player_stat_rankings[index].away_team.players"
              )
                span.player_name.clickable(@click="bestPlayerClick(player)") {{ `${player.name}` }}
            .avator_container.temp_logo_hidden
              v-img.img_circle.clickable.ml-4(
                @click="teamClick(false)",
                :src="ranking.away_team.img"
              )
        .player_bars.d-flex.justify-space-between.mt-5
          .bar.bg_middle_blue(
            :style="{ flexBasis: 'calc(' + getPlayerCompareRate(ranking.stat, true) }"
          )
          .bar.bg_secondary(
            :style="{ flexBasis: 'calc(' + getPlayerCompareRate(ranking.stat, false) }"
          )
        .membership_team.d-flex.justify-space-between
          .team.home_team.clickable(@click="teamClick(true)") {{ ranking.home_team.team_name }}
          .team.away_team.clickable(@click="teamClick(false)") {{ ranking.away_team.team_name }}
  v-container
    v-row(style="margin-top: 100px")
      v-col.text-center(cols="12")
        h5.normal_title_h5 命中率分佈
    v-row.mt-12
      v-col.offset-sm-2.offset-md-0(cols="12", sm="8", md="6")
        p.text-center.position_team_name {{ gameData.home_team_name }}
        position-field#positionField1.mt-3(
          @selectPosition="positionHomeClick",
          :positionList="homeShotPositionList",
          :mainColor="'#487AA4'",
          :subColor="'#96B9DC'"
        )
      v-col.offset-sm-2.offset-md-0(cols="12", sm="8", md="6")
        p.text-center.position_team_name {{ gameData.away_team_name }}
        position-field#positionField2.mt-3(
          @selectPosition="positionAwayClick",
          :positionList="awayShotPositionList",
          :mainColor="'#E47076'",
          :subColor="'#F2B8BB'"
        )
  v-dialog.position-relative(
    v-model="isDialogShow",
    style="background: white; height: 100%; width: 810px"
  )
    .close_btn_container
      v-icon.close_btn(@click="isDialogShow = false", large, color="black") mdi-close
    v-container.position-relative.content_container(ref="content")
      v-row(justify="center")
        .uvideo
          youtube(
            ref="youtube",
            @ready="onYTReady",
            @ended="onYTEnd",
            resize,
            fitParent,
            nocookie,
            style="width: 100%; height: 100%; position: absolute; top: 0; left: 0"
          )

          .yt_cover(v-if="coverYT")
            v-btn.play_all.cover(
              @click="playAllClick",
              rounded="",
              color="#487aa4",
              dark,
              v-ripple="false"
            )
              v-icon(left="")
                | mdi-play
              | 播放全部
      v-row(justify="space-between")
        .reference
          //- span.d-block 影片出處：
          //-   a.text_primary(
          //-     href="https://www.youtube.com/c/ssutv/videos",
          //-     target="_blank"
          //-   ) https://www.youtube.com/c/ssutv/videos
          //- span 影片版權皆歸中華民國大專院校體育總會及轉播單位所有
        v-btn.play_all(
          @click="playAllClick",
          rounded="",
          color="#487aa4",
          dark,
          v-ripple="false"
        )
          v-icon(left="")
            | mdi-play
          | 播放全部
      v-row
        span.player_name_team {{ dialogName }} | {{ dialogTeam }}
        .play_table
          .play_head
            .head.play_play 比賽
            .head.play_play 順序
            .head.play_play
            .head.play_time 時間
            .head.play_player 球員
            .head.play_team 隊伍
            .head.play_event 事件
            .head.play_feedback 問題回報
          .play_content
            .play_item(
              :class="{ selected: currentPlayIndex == index }",
              v-for="(play, index) in playList",
              :key="index"
            ) 
              .content.play_play {{ play.game__name }}
              .content.play_play {{ index + 1 }}
              .content.play_play
                v-icon.play(x-small, color="white") mdi-play
              .content.play_time {{ play.video_start_at }}
              .content.play_player(v-if="dialogNameShowType") {{ dialogName }}
              .content.play_player(v-else) {{ play.season_player__name }}
              .content.play_team {{ dialogTeam }}
              .content.play_event {{ dialogEvent }}
              .content.play_feedback
                v-icon(small) mdi-alert-octagon
              .play_btn(@click="onPlayItemClick(play, index)")
              .feedback_btn(@click="onFeedbackBtnClick(play)")
  dialog-success(
    :title="dialogSuccessData.title",
    @closeSuccessDialog="closeSuccessDialog",
    :dialogSuccess="dialogSuccessShow"
  )
    template(#content)
      p {{ dialogSuccessData.text }}
  dialog-loading(:dialogLoading="dialogLoadingShow")
    template(#content)
      p {{ dialogLoadingData.text }}
  dialog-feedback(
    :dialogFeedback="dialogFeedbackShow",
    :play="feedBackPlay",
    @closeFeedbackDialog="closeFeedbackDialog"
  )
  dialog-login(
    :dialogLogin="dialogLoginShow",
    @closeLoginDialog="closeLoginDialog",
    @loginSuccess="loginSuccess"
  )
</template>

<script>
import StickyColumn from "@/components/common/Table/StickyColumn";
import PercentageCompareBar from "@/components/common/PercentageCompareBar";
import PositionField from "@/components/common/PositionField";
import moment from "moment";
import { getGameData } from "@/api/game";
import { getPlayData, getPlayList } from "@/api/play";
import DialogLoading from "@/components/dialog/DialogLoading";
import DialogSuccess from "@/components/dialog/DialogSuccess";
import DialogFeedback from "@/components/dialog/DialogFeedback";
import DialogLogin from "@/components/dialog/DialogLogin";

export default {
  name: "LeagueGameBoxScore",
  components: {
    StickyColumn,
    PercentageCompareBar,
    PositionField,
    DialogSuccess,
    DialogLoading,
    DialogFeedback,
    DialogLogin,
  },
  data() {
    return {
      league: this.$route.params.league,
      season: this.$route.params.season,
      gameID: this.$route.params.id,
      gameData: {},
      currentPlayerGameData: [],
      tab: 0,

      isDialogShow: false,
      coverYT: true,
      playHeadList: ["球員", "隊伍", "事件", "時間", "播放"],
      isPlayAll: false,
      playAllIndex: 0,
      currentPlayIndex: null,
      dialogNameShowType: true,
      dialogName: "",
      dialogTeam: "",
      dialogEvent: "",

      teamRankingList: [
        {
          type: "fieldGoal",
          title: "投籃",
          home: {
            value: 0,
            deno: 0,
          },
          away: {
            value: 0,
            deno: 0,
          },
        },
        {
          type: "twoPoint",
          title: "兩分",
          home: {
            value: 0,
            deno: 0,
          },
          away: {
            value: 0,
            deno: 0,
          },
        },
        {
          type: "threePoint",
          title: "三分",
          home: {
            value: 0,
            deno: 0,
          },
          away: {
            value: 0,
            deno: 0,
          },
        },
        {
          type: "freeThrow",
          title: "罰球",
          home: {
            value: 0,
            deno: 0,
          },
          away: {
            value: 0,
            deno: 0,
          },
        },
        {
          type: "assist",
          title: "助攻",
          home: {
            value: 0,
          },
          away: {
            value: 0,
          },
        },
        {
          type: "tov",
          title: "失誤",
          home: {
            value: 0,
          },
          away: {
            value: 0,
          },
        },
        {
          type: "rebound",
          title: "總籃板",
          home: {
            value: 0,
          },
          away: {
            value: 0,
          },
        },
        {
          type: "offensiveRebound",
          title: "進攻籃板",
          home: {
            value: 0,
          },
          away: {
            value: 0,
          },
        },
        {
          type: "defensiveRebound",
          title: "防守籃板",
          home: {
            value: 0,
          },
          away: {
            value: 0,
          },
        },
        {
          type: "stl",
          title: "抄截",
          home: {
            value: 0,
          },
          away: {
            value: 0,
          },
        },
        {
          type: "blk",
          title: "阻攻",
          home: {
            value: 0,
          },
          away: {
            value: 0,
          },
        },
      ],

      playList: [],

      feedBackPlay: null,

      homeShotPositionList: [],
      awayShotPositionList: [],
      sortValue: {
        type: "",
        order: "",
      },

      dialogSuccessShow: false,
      dialogSuccessData: {
        title: "",
        text: "",
      },

      dialogLoadingShow: false,
      dialogLoadingData: {
        text: "",
      },
      dialogFeedbackShow: false,
      dialogLoginShow: false,
    };
  },
  watch: {
    isDialogShow() {
      if (!this.isDialogShow) {
        this.$refs.youtube.player.stopVideo();
        this.dialogName = "";
        this.dialogTeam = "";
        this.dialogEvent = "";
        this.isPlayAll = false;
        this.playAllIndex = 0;
        this.currentPlayIndex = null;
        this.playList = [];
        this.coverYT = true;
      }
    },
  },
  created() {
    this.getGameData();
  },
  computed: {
    YTPlayer() {
      return this.$refs.youtube.player;
    },
  },
  methods: {
    getTableSort(type) {
      if (type == this.sortValue.type) {
        if ("ASC" == this.sortValue.order) {
          return ["fas", "caret-up"];
        } else {
          return ["fas", "caret-down"];
        }
      } else {
        return ["fas", "sort"];
      }
    },
    sortClick(type, sortData) {
      if (type == this.sortValue.type) {
        if (this.sortValue.order == "DESC") {
          this.sortValue.order = "ASC";
          if (this.tab == 0) {
            this.currentPlayerGameData = this.sortData(
              this.gameData.home_team_player_stats,
              type,
              sortData,
              "ASC"
            );
          } else if (this.tab == 1) {
            this.currentPlayerGameData = this.sortData(
              this.gameData.away_team_player_stats,
              type,
              sortData,
              "ASC"
            );
          }
        } else if (this.sortValue.order == "ASC") {
          this.sortValue.type = "";
          this.sortValue.order = "";
          if (this.tab == 0) {
            this.currentPlayerGameData = this.gameData.home_team_player_stats;
          } else if (this.tab == 1) {
            this.currentPlayerGameData = this.gameData.away_team_player_stats;
          }
        }
      } else {
        this.sortValue.type = type;
        this.sortValue.order = "DESC";
        if (this.tab == 0) {
          this.currentPlayerGameData = this.sortData(
            this.gameData.home_team_player_stats,
            type,
            sortData,
            "DESC"
          );
        } else if (this.tab == 1) {
          this.currentPlayerGameData = this.sortData(
            this.gameData.away_team_player_stats,
            type,
            sortData,
            "DESC"
          );
        }
      }
    },
    sortData(allList, sortKey, sortData, order) {
      let tempList = Object.assign([], allList);
      if ("INFO" == sortData) {
        if ("ASC" == order) {
          return tempList.sort(function (a, b) {
            return a.total[sortKey] - b.total[sortKey];
          });
        } else if ("DESC" == order) {
          return tempList.sort(function (a, b) {
            return b.total[sortKey] - a.total[sortKey];
          });
        }
      } else if ("BOX" == sortData) {
        if ("ASC" == order) {
          return tempList.sort(function (a, b) {
            return a.total[sortKey] - b.total[sortKey];
          });
        } else if ("DESC" == order) {
          return tempList.sort(function (a, b) {
            return b.total[sortKey] - a.total[sortKey];
          });
        }
      }
    },
    playerClick(player) {
      this.$router.push({
        name: "LeaguePlayer",
        params: {
          league: this.league,
          season: this.season,
          id: player.season_player__player__id,
        },
      });
    },
    bestPlayerClick(player) {
      this.$router.push({
        name: "LeaguePlayer",
        params: {
          league: this.league,
          season: this.season,
          id: player.player_id,
        },
      });
    },
    scrollToTop() {
      const c = this.$refs.content.scrollTop || document.body.scrollTop;
      if (c > 0) {
        window.requestAnimationFrame(this.scrollToTop);
        this.$refs.content.scrollTo(0, c - c / 10);
      }
    },
    getGameData() {
      let temp = {
        game_id: this.gameID,
      };
      getGameData(temp).then((response) => {
        this.gameData = response.data;
        this.switchTable(0);
        this.getTeamRankingData();
        this.getTeamShotPositionData();
      });
    },
    getTeamRankingData() {
      let home_team_stat = this.gameData.home_team_stat;
      let away_team_stat = this.gameData.away_team_stat;

      for (let i in this.teamRankingList) {
        let item = this.teamRankingList[i];
        if ("fieldGoal" == item.type) {
          item.home.value = home_team_stat.fgm;
          item.home.deno = home_team_stat.fga;

          item.away.value = away_team_stat.fgm;
          item.away.deno = away_team_stat.fga;
        } else if ("twoPoint" == item.type) {
          item.home.value = home_team_stat.two_pt_fgm;
          item.home.deno = home_team_stat.two_pt_fga;

          item.away.value = away_team_stat.two_pt_fgm;
          item.away.deno = away_team_stat.two_pt_fga;
        } else if ("threePoint" == item.type) {
          item.home.value = home_team_stat.three_pt_fgm;
          item.home.deno = home_team_stat.three_pt_fga;

          item.away.value = away_team_stat.three_pt_fgm;
          item.away.deno = away_team_stat.three_pt_fga;
        } else if ("freeThrow" == item.type) {
          item.home.value = home_team_stat.ftm;
          item.home.deno = home_team_stat.fta;

          item.away.value = away_team_stat.ftm;
          item.away.deno = away_team_stat.fta;
        } else if ("assist" == item.type) {
          item.home.value = home_team_stat.ast;
          item.away.value = away_team_stat.ast;
        } else if ("tov" == item.type) {
          item.home.value = home_team_stat.tov;
          item.away.value = away_team_stat.tov;
        } else if ("rebound" == item.type) {
          item.home.value = home_team_stat.reb;
          item.away.value = away_team_stat.reb;
        } else if ("offensiveRebound" == item.type) {
          item.home.value = home_team_stat.off_reb;
          item.away.value = away_team_stat.off_reb;
        } else if ("defensiveRebound" == item.type) {
          item.home.value = home_team_stat.def_reb;
          item.away.value = away_team_stat.def_reb;
        } else if ("stl" == item.type) {
          item.home.value = home_team_stat.stl;
          item.away.value = away_team_stat.stl;
        } else if ("blk" == item.type) {
          item.home.value = home_team_stat.blk;
          item.away.value = away_team_stat.blk;
        }
      }
    },
    getTeamShotPositionData() {
      this.homeShotPositionList = this.getShotPositionList(
        this.gameData.home_team_stat
      );
      this.awayShotPositionList = this.getShotPositionList(
        this.gameData.away_team_stat
      );
    },
    getPlayList(seasonPlayerId, stat) {
      this.showLoadingDialog("載入中...");

      let params = {
        game_id: this.gameID,
        season_player_id: seasonPlayerId,
        group_by: "PLAYER",
        stat: stat,
      };

      let data = {};

      getPlayList(params, data)
        .then((response) => {
          this.closeLoadingDialog();
          this.playList = response.data;
          if (this.playList && this.playList.length > 0) {
            this.isDialogShow = true;
          }
        })
        .catch(() => {
          this.closeLoadingDialog();
        });
    },
    getPlayData(playerID, playType) {
      this.showLoadingDialog("載入中...");
      let temp = {
        game_id: this.gameID,
        player_id: playerID,
        play_type: playType,
      };
      getPlayData(temp)
        .then((response) => {
          this.closeLoadingDialog();
          this.playList = response.data;
          if (this.playList && this.playList.length > 0) {
            this.isDialogShow = true;
          }
        })
        .catch(() => {
          this.closeLoadingDialog();
        });
    },
    getPercentageCompareRate(type, isHome) {
      let item, total, rate;

      item = this.teamRankingList.find(function (ranking) {
        return ranking.type == type;
      });

      if (isHome) {
        if (item.home.deno) {
          total =
            item.home.value / item.home.deno + item.away.value / item.away.deno;
          rate = item.home.value / item.home.deno / total;
        } else {
          total = item.home.value + item.away.value;
          rate =
            type != "tov" ? item.home.value / total : item.away.value / total;
        }
      } else {
        if (item.away.deno) {
          total =
            item.home.value / item.home.deno + item.away.value / item.away.deno;
          rate = item.away.value / item.away.deno / total;
        } else {
          total = item.home.value + item.away.value;
          rate =
            type != "tov" ? item.away.value / total : item.home.value / total;
        }
      }
      return rate * 100 + "%";
    },
    getPlayerCompareRate(stat, isHome) {
      let item, total, rate;

      item = this.gameData.player_stat_rankings.find(function (ranking) {
        return ranking.stat == stat;
      });

      if (isHome) {
        total = item.home_team.value + item.away_team.value;
        rate = item.home_team.value / total;
      } else {
        total = item.home_team.value + item.away_team.value;
        rate = item.away_team.value / total;
      }
      return rate * 100 + "%";
    },
    switchTable(index) {
      this.sortValue.type = "";
      this.sortValue.order = "";
      switch (index) {
        case 0:
          this.tab = 0;
          this.currentPlayerGameData = this.gameData.home_team_player_stats;
          break;
        case 1:
          this.tab = 1;
          this.currentPlayerGameData = this.gameData.away_team_player_stats;
          break;
        default:
          break;
      }
    },
    getShotPositionList(teamStat) {
      let tempList = [];

      let PAINT = {
        name: "PAINT",
        fga: teamStat.paint_fga,
        fgm: teamStat.paint_fgm,
        percent: (teamStat.paint_fg_percent * 100).toFixed(1) + "%",
      };
      tempList.push(PAINT);

      let TOP_OF_THE_ARC_2_PTS = {
        name: "TOP_OF_THE_ARC_2_PTS",
        fga: teamStat.top_2pt_fga,
        fgm: teamStat.top_2pt_fgm,
        percent: (teamStat.top_2pt_fg_percent * 100).toFixed(1) + "%",
      };
      tempList.push(TOP_OF_THE_ARC_2_PTS);

      let TOP_OF_THE_ARC_3_PTS = {
        name: "TOP_OF_THE_ARC_3_PTS",
        fga: teamStat.top_3pt_fga,
        fgm: teamStat.top_3pt_fgm,
        percent: (teamStat.top_3pt_fg_percent * 100).toFixed(1) + "%",
      };
      tempList.push(TOP_OF_THE_ARC_3_PTS);

      let LEFT_BASELINE_2_PTS = {
        name: "LEFT_BASELINE_2_PTS",
        fga: teamStat.left_baseline_2pt_fga,
        fgm: teamStat.left_baseline_2pt_fgm,
        percent: (teamStat.left_baseline_2pt_fg_percent * 100).toFixed(1) + "%",
      };
      tempList.push(LEFT_BASELINE_2_PTS);

      let RIGHT_BASELINE_2_PTS = {
        name: "RIGHT_BASELINE_2_PTS",
        fga: teamStat.right_baseline_2pt_fga,
        fgm: teamStat.right_baseline_2pt_fgm,
        percent:
          (teamStat.right_baseline_2pt_fg_percent * 100).toFixed(1) + "%",
      };
      tempList.push(RIGHT_BASELINE_2_PTS);

      let LEFT_WING_2_PTS = {
        name: "LEFT_WING_2_PTS",
        fga: teamStat.left_wing_2pt_fga,
        fgm: teamStat.left_wing_2pt_fgm,
        percent: (teamStat.left_wing_2pt_fg_percent * 100).toFixed(1) + "%",
      };
      tempList.push(LEFT_WING_2_PTS);

      let RIGHT_WING_2_PTS = {
        name: "RIGHT_WING_2_PTS",
        fga: teamStat.right_wing_2pt_fga,
        fgm: teamStat.right_wing_2pt_fgm,
        percent: (teamStat.right_wing_2pt_fg_percent * 100).toFixed(1) + "%",
      };
      tempList.push(RIGHT_WING_2_PTS);

      let LEFT_BASELINE_3_PTS = {
        name: "LEFT_BASELINE_3_PTS",
        fga: teamStat.left_baseline_3pt_fga,
        fgm: teamStat.left_baseline_3pt_fgm,
        percent: (teamStat.left_baseline_3pt_fg_percent * 100).toFixed(1) + "%",
      };
      tempList.push(LEFT_BASELINE_3_PTS);

      let RIGHT_BASELINE_3_PTS = {
        name: "RIGHT_BASELINE_3_PTS",
        fga: teamStat.right_baseline_3pt_fga,
        fgm: teamStat.right_baseline_3pt_fgm,
        percent:
          (teamStat.right_baseline_3pt_fg_percent * 100).toFixed(1) + "%",
      };
      tempList.push(RIGHT_BASELINE_3_PTS);

      let LEFT_WING_3_PTS = {
        name: "LEFT_WING_3_PTS",
        fga: teamStat.left_wing_3pt_fga,
        fgm: teamStat.left_wing_3pt_fgm,
        percent: (teamStat.left_wing_3pt_fg_percent * 100).toFixed(1) + "%",
      };
      tempList.push(LEFT_WING_3_PTS);

      let RIGHT_WING_3_PTS = {
        name: "RIGHT_WING_3_PTS",
        fga: teamStat.right_wing_3pt_fga,
        fgm: teamStat.right_wing_3pt_fgm,
        percent: (teamStat.right_wing_3pt_fg_percent * 100).toFixed(1) + "%",
      };
      tempList.push(RIGHT_WING_3_PTS);

      return tempList;
    },
    getPlayDataByPosition(seasonTeamID, position) {
      this.showLoadingDialog("載入中...");
      let query_param = {
        game_id: this.gameID,
        group_by: "TEAM",
        season_team_id: seasonTeamID,
      };

      let data = { shot_position: position };

      getPlayList(query_param, data)
        .then((response) => {
          this.closeLoadingDialog();
          this.playList = response.data;
          if (this.playList && this.playList.length > 0) {
            this.isDialogShow = true;
          }
        })
        .catch(() => {
          this.closeLoadingDialog();
        });
    },
    dataClick(player, stat, event) {
      this.dialogNameShowType = true;
      this.isPlayAll = false;
      this.playList = [];
      this.dialogName = player.season_player__name;
      this.dialogTeam = player.season_team__name;
      this.dialogEvent = event;
      this.getPlayList(player.season_player__id, stat);
    },
    positionHomeClick(positionData) {
      this.dialogNameShowType = false;
      this.isPlayAll = false;
      this.playList = [];
      // this.dialogName = this.playerData[0].info.name;
      this.dialogTeam = this.gameData.home_team_name;
      this.dialogEvent = positionData.dialogEvent;
      this.getPlayDataByPosition(
        this.gameData.home_team_id,
        positionData.position
      );
    },
    positionAwayClick(positionData) {
      this.dialogNameShowType = false;
      this.isPlayAll = false;
      this.playList = [];
      // this.dialogName = this.playerData[0].info.name;
      this.dialogTeam = this.gameData.away_team_name;
      this.dialogEvent = positionData.dialogEvent;
      this.getPlayDataByPosition(
        this.gameData.away_team_id,
        positionData.position
      );
    },
    playAllClick() {
      this.coverYT = false;
      this.isPlayAll = true;
      this.playAllIndex = 0;
      this.currentPlayIndex = this.playAllIndex;
      if (this.playList && this.playList.length > 0) {
        this.playVideo(this.playList[this.playAllIndex]);
      }
    },
    onYTReady() {
      if (this.playList && this.playList.length > 0) {
        this.YTPlayer.cueVideoById({
          videoId: this.playList[0].video,
        });
      }
    },
    onYTEnd(e) {
      if (this.isPlayAll && e.getVideoLoadedFraction() > 0) {
        if (this.playList && this.playList.length > 0) {
          if (this.playAllIndex + 1 < this.playList.length) {
            this.playAllIndex = this.playAllIndex + 1;

            this.currentPlayIndex = this.playAllIndex;

            this.playVideo(this.playList[this.playAllIndex]);
          } else {
            this.isPlayAll = false;
            this.playAllIndex = 0;
          }
        }
      }
    },
    onPlayItemClick(play, index) {
      this.coverYT = false;
      this.currentPlayIndex = index;
      this.playVideo(play);
    },
    onFeedbackBtnClick(play) {
      play.league = this.league;
      play.team = this.dialogTeam;
      this.feedBackPlay = play;
      this.showFeedbackDialog();
      // if (!this.$store.state.user.accessToken) {
      //   this.showLoginDialog();
      // } else {
      //   this.showFeedbackDialog();
      // }
    },
    playVideo(play) {
      this.scrollToTop();
      var startSeconds =
        play.ft_attempt > 0
          ? moment.duration(play.video_end_at).asSeconds() - 14
          : moment.duration(play.video_start_at).asSeconds();
      this.YTPlayer.loadVideoById({
        videoId: play.game__youtube_ids__0,
        startSeconds: startSeconds - play.video_record_duration,
        endSeconds: moment.duration(play.video_end_at).asSeconds() + 2,
      });
    },
    teamClick(isHomeTeam) {
      let id;

      if (isHomeTeam) {
        id = this.gameData.home_team__team_id;
      } else {
        id = this.gameData.away_team__team_id;
      }

      this.$router.push({
        name: "LeagueTeam",
        params: {
          league: this.league,
          season: this.season,
          id: id,
        },
      });
    },
    getMinutes(temp) {
      let min = parseInt(temp / 60);
      let second = (temp % 60).toFixed(0);
      if (second < 10) {
        return min + ":0" + second;
      }
      return min + ":" + second;
    },
    loginSuccess() {
      this.closeLoginDialog();
    },
    showFeedbackDialog() {
      this.dialogFeedbackShow = true;
    },
    closeFeedbackDialog() {
      this.dialogFeedbackShow = false;
    },
    showLoginDialog() {
      this.dialogLoginShow = true;
    },
    closeLoginDialog() {
      this.dialogLoginShow = false;
    },
    showSuccessDialog(title, text) {
      this.dialogSuccessData.title = title;
      this.dialogSuccessData.text = text;
      this.dialogSuccessShow = true;
    },
    closeSuccessDialog() {
      this.dialogSuccessData.title = "";
      this.dialogSuccessData.text = "";
      this.dialogSuccessShow = false;
    },
    showLoadingDialog(text) {
      this.dialogLoadingData.text = text;
      this.dialogLoadingShow = true;
    },
    closeLoadingDialog() {
      this.dialogLoadingData.text = "";
      this.dialogLoadingShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
@import "@/assets/style/common/dialog_play_video";
@import "@/assets/style/league/game/box_score";
</style>
